.container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Make the container take the full viewport height */
}

#auth-header {
  width: 100vw;
  text-align: center;
}

.grid {
  display: grid;
  gap: 20px;
  /* Adjust the gap between grid items */
}

.button-container {
  margin-top: 20px;
  /* Spacing between Google/Microsoft and Password button */
}

button {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header {
  text-align: center;
}

.login-provider-icon {
  width: 20px;
  height: auto;
  margin-right: 10px;
  /* Adjust this value to increase or decrease padding */
}

.mgka-logo {
  width: 20%;
  height: auto;
  margin-bottom: var(--pico-typography-spacing-vertical);
}

.navbar-logo {
  width: 50px;
  vertical-align: middle;
}

.app-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Increase min width for larger items */
  gap: 20px;
  padding: 20px;
}

.app-grid-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 10px;
  overflow: hidden;
  transition: background 0.5s ease;
  background-color: var(--pico-muted-border-color);
}

.app-grid-item * {
  color: var(--pico-color);
}

.app-grid-item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-align: center;
  box-sizing: border-box;
}

.app-grid-item img {
  width: 80%;
  height: auto;
  margin-bottom: 10px;
  object-fit: contain;
  margin-right: 0px;
}

.app-grid-item h4 {
  margin: 10px 0 5px;
  font-size: 1.1em;
}

.app-grid-item:hover {
  background: var(--gradient-background);
  background-size: 500% 500%;
  animation: gradient-animation 5s ease-in-out infinite;
  transition: ease-in-out 0.3s;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#main-nav ul {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

#user-pfp {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border-color: var(--pico-contrast-border);
}

#hi-name {
  font-size: 16px; /* Ensure the font-size is appropriate */
  line-height: 1.2; /* A small adjustment to the line height */
  display: flex;
  align-items: center; /* Align the text vertically */
}

.dropdown summary {
  display: flex;
  align-items: center; /* Align the image and text within the summary */
}

.container {
  padding: 20px;
}

.settings-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.settings-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.settings-container {
  display: flex;
  align-items: flex-start; /* Align items at the start of the container */
}

.settings-details {
  flex: 1;
  margin-left: 20px; /* Space between image and details */
}

#link-providers-details {
  width: 100%;
}

#link-providers-details[open] {
  /* Adjusting the behavior when details are open */
  display: block;
}

.settings-container img {
  flex-shrink: 0;
  margin-right: 20px; /* Space between image and details */

  width: 10%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
  border-color: var(--pico-contrast-border);
}

.settings-details h2 {
  margin-bottom: 10px;
}

.settings-security {
  margin-top: 30px;
}

.settings-security h3 {
  margin-bottom: 10px;
}

.btn {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;
}

.settings-header {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between the back button and the title */
  margin-bottom: 20px;
  /* Space below the header */
}

.settings-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.settings-header h1 {
  margin: 0;
}

#home-btn {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--pico-foreground-color);
  background-color: var(--pico-muted-border-color);
  border-radius: var(--pico-border-radius);
  border: 1px solid var(--pico-border-color);
  transition: background-color 0.3s ease;
}

#home-btn:hover {
  background-color: var(--pico-muted-hover-color);
}

.back-icon {
  margin-right: 0.5em;
}

.sentry-error-embed-wrapper {
    z-index: 1000 !important;
}

.this-session {
    background-color: rgba(124, 179, 66, 0.38);
}

.terminate-session-btn {
    background-color: var(--pico-form-element-invalid-focus-color);
    color: var(--pico-del-text-color);
    border-color: var(--pico-form-element-invalid-active-border-color);
}