.container-fluid {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

#auth-header {
  text-align: center;
  width: 100vw;
}

.grid {
  gap: 20px;
  display: grid;
}

.button-container {
  margin-top: 20px;
}

button {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  display: flex;
}

header {
  text-align: center;
}

.login-provider-icon {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.mgka-logo {
  margin-bottom: var(--pico-typography-spacing-vertical);
  width: 20%;
  height: auto;
}

.navbar-logo {
  vertical-align: middle;
  width: 50px;
}

.app-grid {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  display: grid;
}

.app-grid-item {
  background-color: var(--pico-muted-border-color);
  border-radius: 10px;
  width: 100%;
  padding-top: 100%;
  transition: background .5s;
  position: relative;
  overflow: hidden;
}

.app-grid-item * {
  color: var(--pico-color);
}

.app-grid-item-content {
  text-align: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
  position: absolute;
  inset: 0;
}

.app-grid-item img {
  object-fit: contain;
  width: 80%;
  height: auto;
  margin-bottom: 10px;
  margin-right: 0;
}

.app-grid-item h4 {
  margin: 10px 0 5px;
  font-size: 1.1em;
}

.app-grid-item:hover {
  background: var(--gradient-background);
  background-size: 500% 500%;
  transition: all .3s ease-in-out;
  animation: 5s ease-in-out infinite gradient-animation;
}

@keyframes gradient-animation {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

#main-nav ul {
  align-items: center;
  display: flex;
}

#user-pfp {
  object-fit: cover;
  border-color: var(--pico-contrast-border);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

#hi-name {
  align-items: center;
  font-size: 16px;
  line-height: 1.2;
  display: flex;
}

.dropdown summary {
  align-items: center;
  display: flex;
}

.container {
  padding: 20px;
}

.settings-info {
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.settings-picture {
  object-fit: cover;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

.settings-container {
  align-items: flex-start;
  display: flex;
}

.settings-details {
  flex: 1;
  margin-left: 20px;
}

#link-providers-details {
  width: 100%;
}

#link-providers-details[open] {
  display: block;
}

.settings-container img {
  object-fit: cover;
  vertical-align: middle;
  border-color: var(--pico-contrast-border);
  border-radius: 50%;
  flex-shrink: 0;
  width: 10%;
  height: auto;
  margin-right: 20px;
}

.settings-details h2 {
  margin-bottom: 10px;
}

.settings-security {
  margin-top: 30px;
}

.settings-security h3 {
  margin-bottom: 10px;
}

.btn {
  cursor: pointer;
  padding: 10px 15px;
  font-size: 14px;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border: none;
  border-radius: 5px;
}

.settings-header {
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.settings-header h1 {
  margin: 0;
}

#home-btn {
  cursor: pointer;
  color: var(--pico-foreground-color);
  background-color: var(--pico-muted-border-color);
  border-radius: var(--pico-border-radius);
  border: 1px solid var(--pico-border-color);
  align-items: center;
  padding: .5em 1em;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
  position: absolute;
  left: 0;
}

#home-btn:hover {
  background-color: var(--pico-muted-hover-color);
}

.back-icon {
  margin-right: .5em;
}

.sentry-error-embed-wrapper {
  z-index: 1000 !important;
}

.this-session {
  background-color: #7cb34261;
}

.terminate-session-btn {
  background-color: var(--pico-form-element-invalid-focus-color);
  color: var(--pico-del-text-color);
  border-color: var(--pico-form-element-invalid-active-border-color);
}
/*# sourceMappingURL=index.4b38ec03.css.map */
